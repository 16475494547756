<template>
  <CampingMap @selectCamping="selectCamping" />
  <SideBar v-if="camping" v-bind="camping" @close="clearCamping" />
</template>

<script>
import CampingMap from './components/CampingMap.vue'
import SideBar from './components/SideBar.vue'

export default {
  name: 'App',
  components: {
    CampingMap,
    SideBar
  },
  data() {
    return {
      camping: null
    }
  },
  methods: {
    selectCamping(camping) {
      this.camping = camping;
    },
    clearCamping() {
      this.camping = null;
    }
  }
}
</script>

<style>
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.dark {
  filter: invert(1) hue-rotate(180deg) grayscale(0.1);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
