export class SelectStarsControl {
  constructor(vm) {
    this.vm = vm;
  }

  onAdd(map) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = 'maplibregl-ctrl maplibregl-ctrl-group mapboxgl-ctrl mapboxgl-ctrl-group';

    const container = document.createElement('div');
    container.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 48 48"><path d="M22 40q-.85 0-1.425-.575Q20 38.85 20 38V26L8.05 10.75q-.7-.85-.2-1.8Q8.35 8 9.4 8h29.2q1.05 0 1.55.95t-.2 1.8L28 26v12q0 .85-.575 1.425Q26.85 40 26 40Z"/></svg>`;
    Object.assign(container.style, {
      width: "29px",
      height: "29px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative"
    });

    const style = document.createElement("style")
    style.innerHTML = ".star-selector{display:none} .open .star-selector{display:flex} .star-selector > div {display: flex;align-items: center;gap: 4px;cursor:pointer} .star-selector > div * {cursor:pointer;user-select: none;}";
    document.head.append(style);

    const element = document.createElement("div");
    element.className = "star-selector";
    element.innerHTML = `
    <div>
      <input type="checkbox" id="oneStar" checked>
      <label for="oneStar">1 étoile</label>
    </div>
    <div>
      <input type="checkbox" id="twoStars" checked>
      <label for="twoStars">2 étoiles</label>
    </div>
    <div>
      <input type="checkbox" id="threeStars" checked>
      <label for="threeStars">3 étoiles</label>
    </div>
    <div>
      <input type="checkbox" id="fourStars" checked>
      <label for="fourStars">4 étoiles</label>
    </div>
    <div>
      <input type="checkbox" id="fiveStars" checked>
      <label for="fiveStars">5 étoiles</label>
    </div>
    <div style="display: flex;align-items: center;gap: 4px;">
      <input type="checkbox" id="other" checked>
      <label for="other">Autres</label>
    </div>`;
    container.addEventListener("click", () => {
      this._container.classList.toggle("open");
    })
    Object.assign(element.style, {
      position: 'absolute',
      zIndex: '99',
      backgroundColor: 'white',
      top: '0',
      flexDirection: 'column',
      alignItems: 'flex-start',
      minWidth: '80px',
      padding: '8px',
      right: '39px',
      borderRadius: '8px',
      boxShadow: '0 1px 2px rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%)'
    })

    Array.from(element.querySelectorAll("input"))
      .forEach(el => el.addEventListener("click", (e) => {
        e.stopImmediatePropagation();
        this.vm[e.target.id] = e.target.checked;
        this.vm.setFilters();
      }));
    this._container.append(container);
    this._container.append(element);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}
