<template>
  <div id="map"></div>
</template>

<script>
import {GeolocateControl, Map} from "maplibre-gl"
import {style} from "@/style";
import {SelectStarsControl} from "@/SelectStars";
import {DarkModeControl} from "@/DarkMode";

export default {
  name: "CampingMap",
  emits: ['selectCamping'],
  methods: {
    clickCamping(camping) {
      this.$emit('selectCamping', camping)
    },
    centerMap(feature, openSidebar, zoom = this.map.getZoom()) {
      const padding = {bottom: 0};
      if (window.innerWidth <= 600 && (openSidebar || document.querySelector("#sidebar"))) {
        padding.bottom = 300;
      }
      this.map.easeTo({
        padding,
        zoom,
        center: feature.geometry.coordinates,
      });
    },
    setFilters() {
      this.map.getSource('campings').setData({
        type: 'FeatureCollection',
        features: this.data.features.filter(f => this.stars.indexOf(f.properties.stars) !== -1)
      });
    },
    setClicked() {
      this.map.setFilter('selected-point', [
        "all",
        ['!', ['has', 'point_count']],
        ['==', ['get', 'id'], this.featureId]
      ]);
      this.map.setFilter('unclustered-point', [
        "all",
        ['!', ['has', 'point_count']],
        ['!=', ['get', 'id'], this.featureId]
      ]);
      this.map.setFilter('cluster-count', [
        "all",
        ['has', 'point_count']
      ]);
      this.map.setFilter('clusters', [
        "all",
        ['has', 'point_count']
      ]);
    },
    initMap() {
      if (this.loaded) return;
      this.loaded = true;
      this.map.on('load', () => {
        this.map.addSource('campings', {
          'type': 'geojson',
          'data': structuredClone(this.data),
          cluster: true,
          clusterMaxZoom: 12,
          clusterRadius: 80
        });

        this.map.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'campings',
          filter: [
            "all",
            ['has', 'point_count']
          ],
          paint: {
            'circle-color': '#41b883',
            'circle-opacity': 0.9,
            'circle-stroke-color': '#2e8961',
            'circle-stroke-opacity': 0.7,
            'circle-stroke-width': 5,
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              20,
              20,
              25,
              40,
              30
            ]
          }
        });

        this.map.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'campings',
          filter: [
            "all",
            ['has', 'point_count']
          ],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['Noto Sans Bold'],
            'text-size': 16
          }
        });

        this.map.addLayer({
          id: 'selected-point',
          type: 'symbol',
          source: 'campings',
          filter: ['==', ['get', 'id'], -1],
          'layout': {
            'icon-image': 'camping-selected',
            'icon-size': 0.2
          }
        });

        this.map.addLayer({
          id: 'unclustered-point',
          type: 'symbol',
          source: 'campings',
          filter: [
            "all",
            ['!', ['has', 'point_count']]
          ],
          'layout': {
            'icon-image': 'camping',
            'icon-size': 0.2
          }
        });

        this.map.on('click', 'unclustered-point', (e) => {
          const features = this.map.queryRenderedFeatures(e.point, {
            layers: ['unclustered-point']
          });
          this.featureId = features[0].properties.id;

          this.setClicked();
          this.clickCamping(features[0].properties);
          this.centerMap(features[0], true);
        });

        this.map.on('click', 'clusters', (e) => {
          const features = this.map.queryRenderedFeatures(e.point, {
            layers: ['clusters']
          });
          const clusterId = features[0].properties.cluster_id;
          this.map.getSource('campings').getClusterExpansionZoom(
              clusterId,
              (err, zoom) => {
                if (err) return;
                this.centerMap(features[0], false, zoom);
              }
          );
        });
      })
    }
  },
  data() {
    return {
      data: null,
      loaded: false,
      map: null,
      featureId: null,
      oneStar: true,
      twoStars: true,
      threeStars: true,
      fourStars: true,
      fiveStars: true,
      other: true
    }
  },
  computed: {
    stars() {
      const data = [];
      if (this.oneStar)
        data.push("1");
      if (this.twoStars)
        data.push("2");
      if (this.threeStars)
        data.push("3");
      if (this.fourStars)
        data.push("4");
      if (this.fiveStars)
        data.push("5");
      if (this.other)
        data.push("other");
      return data
    }
  },
  mounted() {
    fetch("/campings.geojson").then(res => res.json()).then(data => {
      this.data = data;
      this.initMap();
    })
    const map = new Map({
      container: 'map',
      style: style,
      center: [1.1, 47.4],
      zoom: 5
    });
    this.map = map;
    window.map = map;
    map.loadImage(
        '/camping.png',
        function (error, image) {
          if (error) throw error;
          map.addImage('camping', image);
        });
    map.loadImage(
        '/camping-selected.png',
        function (error, image) {
          if (error) throw error;
          map.addImage('camping-selected', image);
        });
    map.addControl(new DarkModeControl())
    map.addControl(new GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      fitBoundsOptions: {
        zoom: 11
      }
    }));
    map.addControl(new SelectStarsControl(this))
  }
}
</script>

<style scoped>
@import "maplibre-gl/dist/maplibre-gl.css";


#map {
  flex: 1;
}
</style>