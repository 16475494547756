<template>
  <div id="sidebar">
    <div style="padding: 16px 24px">
      <div class="header">
        <span class="title">{{ name }}</span>
        <span class="close" @click="() => $emit('close')">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 48 48"><path
                d="M24.05 26.55 13.7 36.9q-.6.6-1.325.6t-1.275-.6q-.6-.55-.6-1.275 0-.725.6-1.275l10.4-10.4-10.45-10.4q-.55-.55-.55-1.275 0-.725.55-1.275.55-.55 1.275-.55.725 0 1.325.55L24 21.35 34.35 11q.55-.55 1.275-.55.725 0 1.325.55.55.6.55 1.35 0 .75-.55 1.3L26.6 24l10.35 10.4q.55.55.55 1.275 0 .725-.55 1.275-.55.55-1.275.55-.725 0-1.225-.55Z"/></svg>
        </span>
      </div>
      <span v-if="stars !== 'other'" class="subtitle" style="color: #70757a">Camping {{
          stars
        }} étoile{{ stars !== "1" ? "s" : "" }}</span>
      <span v-else class="subtitle" style="color: #70757a">Aire naturelle</span>
    </div>
    <hr>
    <div class="actions">
      <div>
        <a :href="mapsLink" target="_blank">
            <span class="direction">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 48 48"><path
                d="M15.7 30.3h3.65v-6.5h8.55v4.7l6.55-6.5-6.55-6.6v4.75H17.5q-.75 0-1.275.55-.525.55-.525 1.3ZM24 44.8q-.75 0-1.45-.2t-1.15-.65L4.05 26.6q-.45-.45-.65-1.15T3.2 24q0-.75.225-1.475.225-.725.625-1.125L21.4 4.05q.45-.45 1.15-.675.7-.225 1.5-.225.75 0 1.45.2t1.15.65L44 21.35q.45.45.65 1.15t.2 1.45q0 .8-.225 1.5t-.675 1.15L26.6 43.95q-.4.4-1.125.625-.725.225-1.475.225Z"/></svg>
        </span>
        <span>Itinéraire</span>
        </a>
      </div>
      <div @click="share">
        <span class="share">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 48 48"><path
                d="M36.5 44.45q-2.55 0-4.325-1.775Q30.4 40.9 30.4 38.4q0-.35.075-.8.075-.45.225-.85l-14.9-8.6q-.8.85-1.925 1.35-1.125.5-2.275.5-2.5 0-4.3-1.775-1.8-1.775-1.8-4.275 0-2.55 1.8-4.3t4.3-1.75q1.15 0 2.25.425T15.8 19.6l14.9-8.5q-.15-.35-.225-.8-.075-.45-.075-.8 0-2.5 1.775-4.275Q33.95 3.45 36.5 3.45q2.5 0 4.275 1.775Q42.55 7 42.55 9.5q0 2.5-1.8 4.275-1.8 1.775-4.3 1.775-1.2 0-2.275-.35T32.3 14l-14.9 8.2q.1.4.175.9.075.5.075.85t-.075.725q-.075.375-.175.775l14.9 8.4q.8-.7 1.825-1.1 1.025-.4 2.325-.4 2.5 0 4.3 1.775 1.8 1.775 1.8 4.275 0 2.5-1.775 4.275Q39 44.45 36.5 44.45Z"/></svg>
        </span>
        <span>Partager</span>
      </div>
    </div>
    <hr>

    <div class="infos">
      <div>
            <span class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 48 48">
            <path
                d="M24 23.8q1.55 0 2.7-1.125Q27.85 21.55 27.85 20q0-1.55-1.15-2.7-1.15-1.15-2.7-1.15-1.6 0-2.7 1.15T20.2 20q0 1.6 1.125 2.7T24 23.8Zm0 19.85q-.35 0-.725-.1t-.675-.35q-7.5-6.6-11.2-12.275Q7.7 25.25 7.7 20.4q0-7.65 4.9-12.2Q17.5 3.65 24 3.65q6.45 0 11.4 4.55t4.95 12.2q0 4.85-3.725 10.525Q32.9 36.6 25.4 43.2q-.3.25-.65.35-.35.1-.75.1Z"/>
        </svg>
            </span>
        <span class="subtitle address"><span>{{ street }}, </span><span>{{
            postal_code
          }} {{ city }}</span></span>
      </div>
      <div v-if="websiteUrl">
            <span class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 48 48"><path
                    d="M24 44.8q-4.35 0-8.15-1.625-3.8-1.625-6.625-4.45T4.8 32.075Q3.2 28.25 3.2 23.9q0-4.35 1.6-8.15 1.6-3.8 4.425-6.575Q12.05 6.4 15.85 4.8T24 3.2q4.35 0 8.15 1.6 3.8 1.6 6.625 4.375T43.2 15.75q1.6 3.8 1.6 8.15 0 4.35-1.6 8.175-1.6 3.825-4.425 6.65-2.825 2.825-6.625 4.45T24 44.8Zm0-3.6q1.75-1.75 2.875-4.075t1.875-5.475H19.3Q20 34.6 21.15 37q1.15 2.4 2.85 4.2Zm-4.5-.65q-1.2-1.8-2.075-3.975-.875-2.175-1.475-4.925h-7.4q1.8 3.45 4.3 5.45t6.65 3.45Zm9.05-.05q3.5-1.1 6.35-3.4 2.85-2.3 4.6-5.45h-7.35q-.65 2.7-1.525 4.875Q29.75 38.7 28.55 40.5ZM7.45 28.4h7.9q-.15-1.3-.175-2.375-.025-1.075-.025-2.125 0-1.25.05-2.225.05-.975.2-2.125H7.45q-.35 1.15-.475 2.1-.125.95-.125 2.25t.125 2.325Q7.1 27.25 7.45 28.4Zm11.25 0h10.65q.2-1.5.25-2.475.05-.975.05-2.025 0-1-.05-1.925t-.25-2.425H18.7q-.2 1.5-.25 2.425-.05.925-.05 1.925 0 1.05.05 2.025.05.975.25 2.475Zm13.95 0h7.95q.3-1.15.425-2.175t.125-2.325q0-1.3-.125-2.25t-.425-2.1h-7.9l.15 2.65q.05.9.05 1.7 0 1.1-.075 2.075-.075.975-.175 2.425Zm-.55-12.1h7.4q-1.65-3.35-4.5-5.65-2.85-2.3-6.5-3.25 1.2 1.8 2.075 3.925.875 2.125 1.525 4.975Zm-12.8 0h9.5q-.55-2.55-1.8-5.05t-3-4.5q-1.55 1.45-2.625 3.65T19.3 16.3Zm-10.75 0H16q.55-2.7 1.375-4.8.825-2.1 2.075-4.05-3.65.95-6.45 3.175-2.8 2.225-4.45 5.675Z"/></svg>
            </span>
        <a :href="websiteUrl" class="subtitle" target="_blank">{{ campingWebsiteHostname }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  props: ["stars", "name", "city", "postal_code", "street", "website", "id"],
  methods: {
    share() {
      navigator.share({
        title: this.name,
        url: this.mapsLink,
      });
    }
  },
  computed: {
    address() {
      return `${this.street}, ${this.postal_code} ${this.city}`;
    },
    mapsLink() {
      return `https://maps.google.com/?daddr=${this.address}`
    },
    websiteUrl() {
      let website = this.website;
      if (website.indexOf(".") === -1)
        return null;
      if (!this.website.startsWith("http"))
        website = "http://" + website;
      return website;
    },
    campingWebsiteHostname() {
      if (!this.websiteUrl)
        return null
      return (new URL(this.websiteUrl)).hostname;
    }
  }
}
</script>

<style scoped>
#sidebar {
  position: fixed;
  z-index: 10;
  top: 60px;
  left: 25px;
  margin: auto;
  width: 400px;
  background: white;
  height: fit-content;
  border-radius: 16px;
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
}

.subtitle {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.25rem;
  display: flex;
}

.title {
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5rem;
  color: rgb(32, 33, 36);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.direction {
  border-radius: 36px;
  width: 36px;
  height: 36px;
  background-color: rgb(26, 115, 232);
  border: 1px solid rgb(26, 115, 232);
  display: flex;
  align-items: center;
  justify-content: center;
}

.direction svg {
  fill: white;
  width: 25px;
  height: 25px;
}

.share {
  border-radius: 36px;
  width: 36px;
  height: 36px;
  background-color: white;
  border: 1px solid rgb(26, 115, 232);
  display: flex;
  align-items: center;
  justify-content: center;
}

.share svg {
  fill: rgb(26, 115, 232);
}

.actions {
  padding: 10px 22px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.actions div,
.actions div a,
.actions div a:active,
.actions div a:visited {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: rgb(26, 115, 232);
  text-decoration: none;
}

.actions > div > span:last-child,
.actions > div > a > span:last-child {
  margin-top: 6px;
}

.infos {
  padding: 12px 24px;
}

.infos div {
  display: flex;
  gap: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.icon {
  width: 24px;
  height: 24px;
}

.infos div svg {
  fill: rgb(26, 115, 232);
}

.infos a,
.infos a:visited,
.infos a:active {
  color: #2c3e50;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.header {
  display: flex;
  justify-content: space-between;
}

.close {
  cursor: pointer;
}

.address {
  white-space: pre;
  display: inline-flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {
  #sidebar {
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    border-radius: 16px 16px 0 0;
    width: 100%;
  }
}
</style>